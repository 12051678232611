app-settings-pages,
app-settings-emails,
app-settings-texts,
app-settings-configuration {
  .settings {
    &-configuration,
    &-pages,
    &-emails,
    &-texts {
      .ql-container,
      .ql-editor {
        height: auto;

        a {
          color: darken(map-get($colors, primary), 10);
        }
      }
    }
  }
}
