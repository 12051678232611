app-integrations {
  .integrations {
    &-card {
      @include transition;

      cursor: pointer;
      opacity: 0.6;
      position: relative;
      z-index: 1;
      overflow: none;

      &--isNotAvailable {
        border-top-right-radius: 0.3rem;
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        padding: 0.5rem 1rem;
        background: map-get($colors, primary);
        z-index: 11;
      }

      &--isSetup,
      &:hover {
        opacity: 1;
      }
    }
  }
}
