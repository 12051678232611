/* You can add global styles to this file, and also import other style files */
$colors: (
  primary: #1cbac8,
  light: #ecf0f7,
  danger: #ff2721,
  warning: #ffb921,
  success: #21ce99,
  greylight: #8a8da2,
  dark: #333333
);

@import '../../../src/styles';

@import './app/protected/settings/settings.component';
@import './app/protected/settings/general/settings.general.component';
@import './app/protected/integrations/integrations.component';
@import './app/shared/components/modals/subscriptions/modals.subscriptions.component';
